$heading-color: #000036;
$para-color: #656565;

$base-color: #2ecc71;
$base-color-two: #000036;

//
$body-bg: #132D39;
$section-bg: #f0f8ff;
// $section-bg: #F7F7FA

// border-color
$border-color: #e5e5e5;

// color variable for Bootstrap
$primary: #7367f0;
$secondary: #00003699;
$success: #28c76f;
$danger: #ea5455;
$warning: #ff9f43;
$info: #1e9ff2;
$dark: #10163A;
$light: #bcc7da;
$muted: #a0a0a0;
