/* card css start */
.custom--card {
  box-shadow: 0 5px 15px rgba($base-color-two, 0.06);
  @extend %bg--white;
  @extend %bs-8;
  @extend %overflow-hidden;
  .card-header {
    background-color: #fff;
    padding: rem(15px) rem(25px);
    border-color: rgba(#000, 0.05);
    .card-title {
      font-weight: 500; } }
  .card-body {
    padding: rem(25px);
    @include media(575px) {
      padding: rem(15px); } }
  &.style--two {
    border-top: 2px solid $base-color;
    .card-header {
      background-color: transparent; } } }
/* card css end */
