/* product-sidebar css start */
.product-widget + .product-widget,
.blog-widget + .blog-widget,
.forum-widget + .forum-widget {
  margin-top: rem(30px); }
.product-widget, .blog-widget, .forum-widget {
  @extend %bg--white;
  @extend %bs-5;
  @include media(991px) {
    background-color: $section-bg; }
  &__title {
    padding: rem(15px) rem(20px);
    font-size: rem(18px);
    border-bottom: 1px solid $border-color; }
  &__menu {
    li {
      border-bottom: 1px solid $border-color;
      &:last-child {
        border-bottom: none; }
      >a {
        color: $para-color;
        font-size: rem(14px);
        font-weight: 600;
        padding: rem(12px) rem(20px);
        @extend %d-flex;
        justify-content: space-between;
        @extend %align-center;
        @extend %transition;
        &:hover {
          @extend %base-color;
          @extend %text--white;
          .list-amount {
            color: $para-color; } }
        .list-amount {
          font-size: rem(12px);
          font-weight: 700;
          line-height: 1;
          padding: rem(5px) rem(8px);
          @include border-radius(45px);
          background-color: darken($section-bg, 3%);
          @extend %transition; } } } } }
.blog-widget,
.forum-widget {
  @include media(991px) {
    background-color: #fff; } }

.leadboard-single {
  @extend %d-flex;
  padding: rem(12px) rem(20px);
  &__thumb {
    width: 50px;
    height: 50px;
    @extend %bs-50;
    @extend %overflow-hidden; }
  &__content {
    width: calc(100% - 50px);
    padding-left: rem(15px); } }

.product-widget-search {
  padding: rem(15px) rem(20px); }

.product-sidebar {
  @extend %transition;
  &.active {
    left: 0; }
  @include media(991px) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 350px;
    box-shadow: 10px 0 20px rgba($base-color-two, 0.15);
    padding: rem(20px);
    background-color: #fff;
    max-height: 100vh;
    overflow: auto;
    left: -360px; }
  @include media(575px) {
    width: 100%;
    left: -105%; }
  .product-sidebar-close {
    @extend %position-absolute;
    top: 0;
    right: 0;
    background-color: $danger;
    @extend %text--white;
    font-size: rem(20px);
    display: none;
    @include media(991px) {
      display: inline-block; } } }


.res-filter-bar {
  padding: rem(10px) rem(20px);
  justify-content: space-between;
  @extend %align-center;
  margin-bottom: rem(20px);
  @extend %bs-5;
  @extend %base-color-two;
  display: none;
  @include media(991px) {
    display: flex;
    flex-wrap: wrap; }
  .title {
    @extend %text--white; }
  .res-filter-btn {
    padding: 0;
    font-size: rem(22px);
    line-height: 1;
    background-color: transparent;
    @extend %text--white; } }
/* product-sidebar css end */
