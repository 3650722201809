/* product details section css start */
.product-details-top {
  padding-top: rem(50px); }
.product-details-title {
  font-size: rem(32px);
  @include media(1199px) {
    font-size: rem(24px); } }
.ratings {
  i {
    color: #F2B827; } }
.product-details-meta {
  margin: rem(-3px) rem(-15px);
  li {
    padding: rem(3px) rem(15px); }
  .ratings {
    i {
      font-size: rem(20px);
      line-height: 1; } } }

.product-details-menu {
  border-bottom: 1px solid $border-color;
  @extend %d-flex;
  li {
    margin: 0 rem(5px);
    &:first-child {
      margin-left: 0; }
    &:hover {
      a {
        &::after {
          opacity: 1;
          background-color: #ccc; } } }
    &.active {
      &:hover {
        a {
          &::after {
            @extend %base-color; } } }
      a {
        &::after {
          opacity: 1; } } }
    a {
      color: $para-color;
      font-size: rem(14px);
      font-weight: 600;
      padding: 0 rem(10px) rem(6px) rem(10px);
      @extend %position-relative;
      @include media(480px) {
        padding: 0 rem(3px) rem(5px) rem(3px);
        font-size: rem(13px); }
      &::after {
        @extend %position-absolute;
        content: '';
        bottom: 0;
        left: 0;
        @extend %w-100;
        height: 2px;
        @extend %base-color;
        opacity: 0; } } } }

.product-details-main {
  margin-top: rem(50px); }

.product-details-widget {
  padding: rem(20px);
  // @extend %section-bg
  background-color: #eef0f663;
  border: 1px solid $border-color;
  @extend %bs-8;
  & + & {
    margin-top: rem(30px); }
  &__title {
    margin-bottom: rem(20px); } }

.product-price-top {
  cursor: pointer;
  padding-bottom: rem(10px);
  border-bottom: 1px solid $border-color; }
.product-price-title {
  .selected-title {
    font-weight: 600;
    font-size: rem(18px); } }
.product-price-title {
  i {
    color: #777; } }
.product-price {
  font-size: rem(24px);
  font-weight: 700; }

.licence-info-list {
  margin-top: rem(15px);
  li + li {
    margin-top: rem(5px); } }

.licence-popup {
  @extend %position-absolute;
  top: 70px;
  left: 20px;
  width: calc(100% - 40px);
  @extend %bg--white;
  margin-top: rem(20px);
  @extend %bs-5;
  box-shadow: 0 5px 20px rgba($base-color-two, 0.05);
  opacity: 0;
  visibility: hidden;
  @extend %transition;
  z-index: 999999999999;
  &.active {
    top: 55px;
    opacity: 1;
    visibility: visible; }
  &::before {
    @extend %position-absolute;
    content: '';
    top: -8px;
    left: 20px;
    width: 15px;
    height: 15px;
    @extend %bg--white;
    @include transform(rotate(45deg));
    @include border-radius(3px); } }
.license-block {
  padding: rem(15px);
  border-bottom: 1px solid $border-color;
  cursor: pointer;
  &:last-child {
    border: none; }
  .title {
    font-weight: 500;
    font-size: rem(16px);
    i {
      color: $success; } }
  .price {
    color: $dark;
    font-size: rem(18px);
    font-weight: 700; }
  p {
    font-size: rem(14px); } }

.product-details-info-list {
  >li {
    padding: rem(20px) 0;
    border-bottom: 1px solid $border-color;
    &:first-child {
      padding-top: 0; }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0; }
    p {
      font-size: rem(14px);
      word-break: break-all; } }
  .title {
    color: $secondary;
    margin-bottom: rem(10px); } }
.product-details-tags {
  margin: rem(-3px);
  li {
    padding: rem(3px);
    a {
      padding: rem(5px) rem(12px);
      font-size: rem(12px);
      @extend %bg--white;
      @include border-radius(3px);
      color: $secondary;
      font-weight: 600;
      &:hover {
        @extend %base-color;
        @extend %text--white; } } } }

.product-details-thumb-area {
  padding: 20px;
  // @extend %section-bg
  background-color: #eef0f663;
  border: 1px solid $border-color;
  @extend %bs-8;
  @include media(575px) {
    padding: rem(10px); } }

.product-details-thumb-bottom {
  .btn--group {
    @include media(767px) {
      width: 100%; } }
  .right {
    @include media(767px) {
      width: 100%;
      margin-top: rem(10px); } } }

.product-share {
  margin: rem(-2px) rem(-5px) rem(-2px) 0;
  .caption {
    font-weight: 700;
    @include media(575px) {
      font-size: rem(14px); } }
  li {
    &:not(.caption) {
      padding: rem(2px) rem(5px);
      a {
        width: 35px;
        height: 35px;
        border: 1px solid darken($border-color, 5%);
        color: $para-color;
        @extend %d-flex;
        @extend %align-center;
        @extend %justify-center;
        font-size: rem(15px);
        @extend %bs-5;
        @include media(575px) {
          width: 30px;
          height: 30px;
          font-size: rem(13px); }
        &:hover {
          @extend %base-color;
          @extend %text--white;
          border-color: $base-color; } } } } }

.product-details-content {
  margin-top: rem(40px);
  padding: rem(20px);
  background-color: #eef0f663;
  @extend %position-relative;
  &.show-more {
    &::after {
      opacity: 0; } }
  &::after {
    @extend %position-absolute;
    content: '';
    bottom: 0;
    left: 0;
    @extend %w-100;
    height: 150px;
    @extend %bg--white;
    background: -webkit-linear-gradient(to bottom, rgba(#fff, 0), #fff);
    background: linear-gradient(to bottom, rgba(#fff, 0), #fff);
    opacity: 0;
    @include media(991px) {
      opacity: 1; } }

  &.show-more {
    height: auto; }
  @include media(991px) {
    height: 500px;
    overflow: hidden; }
  p, img {
    margin-bottom: rem(15px); }
  pre {
    margin-top: 20px;
    font-size: 12px;
    text-align: left;
    white-space: pre-line;
    padding: rem(10px) rem(20px);
    @extend %bg--white;
    color: #656565; }
  h1,h2,h3,h4,h5,h6 {
    margin-bottom: rem(15px); }
  h2 {
    font-size: rem(28px); } }
.product-details-more-btn-area {
  display: none;
  @include media(991px) {
    display: block; }
  .product-details-more-btn {
    background-color: transparent;
    font-weight: 600;
    font-size: rem(18px);
    @extend %text--base;
    @extend %position-relative;
    &.active {
      &::after {
        content: "\f106"; }
      .more-text {
        display: none; }
      .less-text {
        display: block; } }
    &::after {
      @extend %position-absolute;
      content: "\f107";
      top: -2px;
      right: -8px;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      @include transform(translateY(5px)); }
    .less-text {
      display: none; } } }

.scroll-menu {
  padding: rem(5px) 0;
  @extend %bg--white;
  box-shadow: 0 3px 8px rgba($base-color-two, 0.1);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  @extend %w-100;
  z-index: 99;
  &.menu-fixed {
    display: block; }
  .product-details-menu {
    border-bottom: none;
    li {
      @include media(991px) {
        margin: 0; } } } }

.comment-block {
  // @extend %bg--white
  background-color: #f1f1f126;
  border: 1px solid $border-color;
  @extend %bs-5;
  & + & {
    margin-top: 30px; } }

.single-comment {
  padding: rem(20px);
  border-top: 1px solid $border-color;
  &:first-child {
    border-top: none; }
  &__top {
    @extend %d-flex;
    @extend %align-center;
    .thumb {
      width: 60px;
      max-height: 60px;
      @extend %bs-5;
      @extend %overflow-hidden;
      img {
        @extend %w-100;
        @extend %h-100;
        @extend %obj-fit; } }
    .content {
      width: calc(100% - 60px);
      padding-left: rem(20px);
      @extend %d-flex;
      @extend %align-center;
      justify-content: space-between;
      @include media(420px) {
        display: block; } } }
  &__content {
    font-size: rem(14px);
    margin-top: rem(15px); }
  .comment-time {
    font-size: rem(13px); } }

.single-review {
  // @extend %bg--white
  background-color: #f1f1f126;
  @extend %bs-5;
  border: 1px solid $border-color;
  & + & {
    margin-top: 20px; }
  &__top {
    padding: rem(10px) rem(20px);
    border-bottom: 1px solid $border-color;
    @extend %d-flex;
    @extend %align-center;
    justify-content: space-between;
    .left {
      @extend %d-flex;
      @extend %align-center; } }
  &__content {
    padding: rem(10px) rem(20px); } }
/* product details section css end */
