/* form css start */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none; }

.form-group {
  margin-bottom: rem(15px); }
.form--control {
  padding: rem(10px) rem(20px);
  border: 1px solid $border-color;
  @extend %w-100;
  background-color: #fff;
  @include border-radius(3px);
  color: #000;
  height: rem(50px);
  &:focus {
    background-color: #fff;
    border-color: $base-color !important;
    box-shadow: 0 0 5px rgba($base-color, 0.35);
    color: #000; }
  &[readonly] {
    background-color: #fff; }
  &.style--two {
    background-color: #e8eef2;
    border-color: #0000000f;
    &:focus {
      @extend %bg--white; } }
  &.form-control-md {
    height: 45px; }
  &.form-control-sm {
    height: 35px; } }

.form-control[type=file] {
  line-height: 36px; }

.custom-icon-field {
  position: relative;
  i {
    position: absolute;
    top: 15px;
    left: 12px; }
  i[class*="la-"] {
    font-size: rem(20px); }
  .form--control {
    padding-left: rem(40px); } }

.select {
  padding: rem(10px) rem(20px);
  @extend %w-100;
  border: 1px solid darken($border-color, 5%);
  cursor: pointer;
  color: $para-color;
  background-color: #fff;
  height: rem(50px);
  @include border-radius(4px);
  option {
    padding: rem(10px) 0;
    display: block;
    border-top: 1px solid #e5e5e5; }
  &.select--sm {
    height: rem(35px);
    font-size: rem(14px);
    padding: rem(5px); } }
textarea {
  min-height: rem(150px) !important;
  resize: none;
  @extend %w-100; }
label {
  color: lighten($para-color, 5%);
  margin-bottom: rem(10px);
  font-size: rem(14px);
  font-weight: 500; }
.input-group>.form--control,
.input-group>.select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0; }
.input-group {
  select {
    background-color: transparent;
    border: none; }
  .form--control.style--two ~ .input-group-text {
    border-color: transparent; } }

.custom--radio {
  position: relative;
  padding-left: 0;
  input[type=radio] {
    @extend %w-100;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    cursor: pointer;
    &:checked {
      ~ label {
        &::before {
          border-width: 2px;
          border-color: $base-color; }
        &::after {
          opacity: 1; } } } }
  label {
    margin-bottom: 0;
    position: relative;
    padding-left: 20px;
    font-size: rem(14px);
    font-weight: 400;
    &::before {
      position: absolute;
      content: '';
      top: 4px;
      left: 0;
      width: 15px;
      height: 15px;
      border: 1px solid #888888;
      @include border-radius(50%);
      @include transition(all 0.3s); }
    &::after {
      position: absolute;
      content: '';
      top: 8px;
      left: 4px;
      width: 7px;
      height: 7px;
      background-color: $base-color;
      @include border-radius(50%);
      opacity: 0;
      @include transition(all 0.3s); } } }

.custom--checkbox {
  padding-left: rem(25px);
  input {
    display: none;
    &:checked {
      ~ label {
        &::before {
          content: "\f14a";
          color: $base-color; } } } }
  label {
    position: relative;
    font-size: rem(15px);
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 0;
    &::before {
      position: absolute;
      content: "\f04d";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      top: 3px;
      left: rem(-25px);
      font-size: rem(20px);
      line-height: 1;
      @extend %transition; } } }
/* form css end*/
