/* why choose section css start */
.choose-us-list {
  @include media(991px) {
    text-align: center;
    margin: rem(-10px) rem(-10px) rem(30px) rem(-10px); }
  @include media(575px) {
    margin: rem(-5px) rem(-7px) rem(15px) rem(-7px); }
  li + li {
    margin-top: rem(25px);
    @include media(991px) {
      margin-top: 0; } }
  li {
    font-size: rem(24px);
    font-weight: 600;
    color: $heading-color;
    @extend %d-inline-block;
    @extend %position-relative;
    @extend %z-index-p;
    @extend %transition;
    @include media(1399px) {
      font-size: rem(20px); }
    @include media(1199px) {
      font-size: rem(18px); }
    @include media(991px) {
      padding: rem(10px) rem(10px); }
    @include media(767px) {
      font-size: rem(16px); }
    @include media(575px) {
      padding: rem(5px) rem(7px); }
    &.active {
      @extend %text--base;
      &::after,
      &::before {
        @extend %base-color; } }
    &::after,
    &::before {
      @extend %position-absolute;
      @extend %position-absolute;
      @extend %transition;
      content: '';
      top: 50%;
      @include media(575px) {
        display: none; } }
    &::after {
      left: 110%;
      width: 200px;
      height: 6px;
      margin-top: -3px;
      background-color: #737391;
      z-index: -2;
      @include media(1399px) {
        height: 5px;
        margin-top: -2.5px; }
      @include media(991px) {
        left: 50%;
        width: 4px;
        height: 150px;
        margin-left: -2px;
        top: 100%;
        margin-top: 0; } }
    &::before {
      left: 106%;
      margin-top: -7px;
      width: 14px;
      height: 14px;
      background-color: #737391;
      @extend %bs-50;
      @extend %z-index-c;
      @include media(1399px) {
        width: 12px;
        height: 12px; }
      @include media(1199px) {
        margin-top: -6px; }
      @include media(991px) {
        left: 50%;
        margin-left: -6px;
        top: 90%;
        margin-top: 0; } } }
  &.style--two {
    @include media(991px) {
      margin: 30px -10px -10px -10px; }
    @include media(575px) {
      margin: rem(10px) rem(-7px) rem(-5px) rem(-7px); }
    li {
      &::after {
        left: auto;
        right: 110%;
        @include media(991px) {
          right: auto;
          left: 50%;
          top: auto;
          bottom: 100%; } }
      &::before {
        left: auto;
        right: 106%;
        @include media(991px) {
          left: 50%;
          right: auto;
          top: auto;
          bottom: 90%; } } } } }

.choose-thumb {
  @extend %bs-10;
  @extend %overflow-hidden;
  @extend %position-relative; }

.video-button {
  @extend %position-absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
  @extend %text--white;
  @extend %bs-50;
  @extend %base-color;
  @include animation(pulse 2000ms linear infinite);
  @extend %z-index-p;
  @include media(1199px) {
    width: 80px;
    height: 80px;
    margin: -40px 0 0 -40px; }
  @include media(380px) {
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px; }
  &::before, &::after {
    opacity: 0.15;
    @extend %positionTwo;
    @extend %base-color;
    @extend %z-index-c;
    @extend %bs-50; }
  &::before {
    @include animation(inner-ripple 2000ms linear infinite); }
  &::after {
    @include animation(outer-ripple 2000ms linear infinite); }
  i {
    margin-left: 10px;
    font-size: 42px;
    color: #fff; } }
/* why choose section css end */

/* featured product section css start */
.product-card {
  @extend %bs-8;
  @extend %overflow-hidden;
  box-shadow: 0 0.75rem 1.5rem #12263f08;
  height: 100%;
  @extend %transition;
  &:hover {
    @extend %trans-y;
    box-shadow: 0 0.95rem 2rem #12263f15; }
  &__content {
    @extend %bg--white;
    padding: rem(16px) rem(20px); }
  &__title {
    font-size: rem(22px);
    @include media(1199px) {
      font-size: rem(20px); }
    @include media(767px) {
      font-size: rem(18px); } }
  &__rating {
    color: #F2B827;
    font-size: rem(18px); }
  &__price {
    font-size: rem(20px);
    font-weight: 600;
    @include media(767px) {
      font-size: rem(18px); } } }

.product-card-two {
  @extend %bg--white;
  @extend %bs-5;
  @extend %overflow-hidden;
  height: 100%;
  box-shadow: 0 0.75rem 1.5rem #12263f08;
  @extend %transition;
  &:hover {
    @extend %trans-y;
    box-shadow: 0 0.95rem 2rem #12263f15; }
  &__content {
    padding: rem(15px) rem(20px); }
  &__title {
    font-size: rem(18px);
    @extend %text-center;
    @include media(1399px) {
      font-size: rem(16px); }
    a {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; } }
  &__rating {
    color: #F2B827;
    font-size: rem(18px);
    font-size: rem(15px); }
  &__footer {
    padding: rem(15px) rem(20px);
    border-top: 1px solid $border-color; }
  &__price {
    @extend %text--base;
    font-weight: 700; } }
.icon-list {
  margin: rem(-2px) rem(-3px);
  li {
    padding: rem(2px) rem(3px);
    a {
      color: #777;
      width: rem(24px);
      height: rem(22px);
      @extend %d-flex;
      @extend %align-center;
      @extend %justify-center;
      font-size: rem(11px);
      border: 1px solid rgba(#000, 0.22);
      @include border-radius(3px); } } }
.category-label {
  font-size: rem(14px);
  color: $para-color;
  font-weight: 600;
  img {
    max-height: 30px; } }
/* featured product section css end */

/* latest product section css start */
.latest-product-area {
  margin: rem(-10px);
  .latest-product-item {
    padding: rem(10px);
    height: 100%;
    @media (min-width: 0px) and (max-width: 479px) {
      width: calc(100% / 2);
      &:nth-child(2n+2) .hover-content, {
        left: auto;
        right: 100%; } }
    @media (min-width: 480px) and (max-width: 575px) {
      width: calc(100% / 3);
      &:nth-child(3n+3) .hover-content, {
        left: auto;
        right: 100%; } }
    @media (min-width: 576px) and (max-width: 767px) {
      width: calc(100% / 3);
      &:nth-child(3n+3) .hover-content, {
        left: auto;
        right: 100%; } }
    @media (min-width: 768px) and (max-width: 991px) {
      width: calc(100% / 4);
      &:nth-child(4n+3) .hover-content,
      &:nth-child(4n+4) .hover-content, {
        left: auto;
        right: 100%; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      width: calc(100% / 6);
      &:nth-child(6n+4) .hover-content,
      &:nth-child(6n+5) .hover-content,
      &:nth-child(6n+6) .hover-content {
        left: auto;
        right: 100%; } }
    @media (min-width: 1200px) and (max-width: 1399px) {
      width: calc(100% / 7);
      &:nth-child(7n+5) .hover-content,
      &:nth-child(7n+6) .hover-content,
      &:nth-child(7n+7) .hover-content {
        left: auto;
        right: 100%; } }
    @media (min-width: 1400px) {
      width: calc(100% / 8);
      &:nth-child(8n+6) .hover-content,
      &:nth-child(8n+7) .hover-content,
      &:nth-child(8n+8) .hover-content {
        left: auto;
        right: 100%; } } }

  .latest-product {
    @extend %position-relative;
    border: 1px solid #e5e5e5;
    @extend %bs-8;
    height: 100%;
    &__thumb {
      img {
        @include border-radius(8px 8px 0 0); } }
    &__content {
      @extend %section-bg;
      padding: rem(10px);
      @include border-radius(0 0 8px 8px);
      .title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        @extend %overflow-hidden;
        text-overflow: ellipsis; }
      span {
        font-weight: 600;
        color: $heading-color; } }
    .hover-content {
      width: 590px;
      @extend %section-bg;
      padding: rem(10px);
      @extend %position-absolute;
      left: 100%;
      bottom: 0;
      box-shadow: 0 0.5rem 2rem #12263f25;
      @extend %z-index-p;
      @include media(1399px) {
        width: 400px; }
      &__text {
        padding: rem(20px); }
      &__title {
        font-size: rem(22px);
        @include media(1399px) {
          font-size: rem(20px); } } } } }
/* latest product section css end */


/* overview section css start */
.overview-item {
  @extend %text-center;
  $root: &;
  &:hover {
    #{$root}__icon {
      @extend %base-color;
      @extend %trans-y;
      i {
        @extend %text--white; } } }
  &__icon {
    width: 200px;
    height: 200px;
    @extend %bs-50;
    border: 2px solid $base-color;
    display: inline-flex;
    flex-flow: column;
    @extend %justify-center;
    @extend %align-center;
    @extend %transition;
    @include media(1399px) {
      width: 180px;
      height: 180px; }
    @include media(575px) {
      width: 120px;
      height: 120px; }
    @include media(420px) {
      width: 100px;
      height: 100px; }
    i {
      font-size: rem(42px);
      line-height: 1;
      @extend %text--base;
      @extend %transition;
      @include media(1399px) {
        font-size: rem(38px); }
      @include media(575px) {
        font-size: rem(26px); }
      @include media(420px) {
        font-size: rem(24px); } } }
  &__amount {
    font-size: rem(36px);
    @extend %text--white;
    line-height: 1.3;
    font-weight: 700;
    margin-top: rem(10px);
    @include media(1399px) {
      font-size: rem(32px); }
    @include media(575px) {
      font-size: rem(22px); }
    @include media(420px) {
      font-size: rem(18px); } }
  &__content {
    margin-top: rem(20px);
    @include media(991px) {
      margin-top: rem(10px); } }
  &__title {
    @include media(1399px) {
      font-size: rem(20px); }
    @include media(575px) {
      font-size: rem(16px); }
    @include media(420px) {
      font-size: rem(14px); } } }
/* overview section css end */


/* cta section css start */
.human-support-card {
  $root: &;
  padding: rem(50px) rem(15px) rem(75px) rem(15px);
  border: 3px solid #f6f6f6;
  @extend %bg--white;
  @extend %transition;
  @extend %bs-8;
  @include media(1199px) {
    padding: rem(30px) rem(15px) rem(55px) rem(15px); }
  &:hover {
    @extend %base-color;
    box-shadow: 0 10px 35px rgba($base-color-two, 0.1);
    border-color: $base-color;
    @extend %trans-y;
    #{$root}__amount,
    #{$root}__title {
      @extend %text--white; } }
  &__amount {
     font-size: rem(52px);
     font-weight: 700;
     @extend %text--base;
     @extend %transition;
     @include media(1199px) {
      font-size: rem(42px); } }
  &__title {
    font-size: rem(16px);
    font-weight: 500;
    @extend %transition; } }

.cta-card {
  padding: rem(40px) rem(100px);
  border: 1px solid transparent;
  height: 100%;
  @extend %bs-8;
  @extend %section-bg;
  @extend %text-center;
  @extend %transition;
  @include media(1399px) {
    padding: rem(40px) rem(70px); }
  @include media(1199px) {
    padding: rem(30px); }
  @include media(575px) {
    padding: rem(20px); }
  &:hover {
    @extend %bg--white;
    box-shadow: 0 10px 25px rgba($base-color-two, 0.05);
    border-color: $base-color;
    @extend %trans-y; }
  &__icon {
    i {
      font-size: rem(66px);
      line-height: 1;
      @extend %text--base;
      @include media(991px) {
        font-size: rem(48px); } } }
  &__content {
    margin-top: rem(30px);
    @include media(991px) {
      margin-top: rem(20px); }
    p {
      margin: rem(10px) 0 rem(20px) 0; } }
  &__title {
    @include media(991px) {
      font-size: rem(22px); } } }
/* cta section css end */

/* testimonial section css start */
.testimonial-content {
  padding: 0 rem(50px);
  @extend %position-relative;
  @extend %text-center;
  @include media(480px) {
    padding: 0 rem(25px); }
  @include media(340px) {
    padding: 0; }
  &::before,
  &::after {
    @extend %position-absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    top: 6px;
    @extend %text--white;
    font-size: rem(32px);
    line-height: 1;
    @include media(480px) {
      font-size: rem(24px); }
    @include media(340px) {
      display: none; } }
  &::before {
    content: "\f10d";
    left: 0; }
  &::after {
    content: "\f10e";
    right: 0; } }
.testimonial-slider {
  .slick-list {
    margin: 0 -10px; }
  .single-slide {
    padding: 0 10px; }
  .slick-dots {
    @extend %d-flex;
    @extend %justify-center;
    li {
      margin: 0 3px;
      &.slick-active {
        button {
          @extend %base-color;
          width: 25px;
          @include border-radius(999px); } }
      button {
        padding: 0;
        font-size: 0;
        width: 10px;
        height: 10px;
        @extend %bs-50;
        background-color: rgba(#fff, 0.35);
        @extend %transition; } } } }
/* testimonial section css end */

/* giving back section css start */
.giving-card {
  @extend %d-flex;
  @extend %bg--white;
  @extend %bs-8;
  @extend %overflow-hidden;
  @extend %transition;
  box-shadow: 0 5px 15px rgba($base-color-two, 0.05);
  &:hover {
    box-shadow: 0 10px 15px rgba($base-color-two, 0.05);
    @extend %trans-y; }
  &:nth-child(even) & {
    &__content {
      order: -1;
      @include media(991px) {
        order: 1; } } }
  & + & {
    margin-top: rem(50px); }
  &__thumb {
    width: 48%;
    @include media(991px) {
      width: 100%; }
    img {
      height: 100%;
      @extend %obj-fit; } }
  &__content {
    width: 52%;
    padding: rem(25px);
    @extend %d-flex;
    @extend %justify-center;
    @extend %align-center;
    @include media(991px) {
      width: 100%; }
    .title {
      font-size: rem(28px);
      margin-right: rem(10px);
      @include media(1199px) {
        font-size: rem(24px); }
      @include media(575px) {
        font-size: rem(20px); } }
    .caption {
      font-weight: 600; }
    .date {
      font-weight: 600;
      margin-top: rem(10px); }
    &-inner {
      @extend %w-100; } } }
/* giving back section css end */

/* timeline section css start */
.timeline-wrapper {
  @extend %d-flex;
  justify-content: space-between;
  @extend %position-relative;
  align-items: flex-start;
  padding-top: 150px;
  @include media(1199px) {
    padding-top: 100px; }
  @include media(991px) {
    padding-top: 75px; }
  &::after {
    @extend %position-absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background-color: #e5e5e5; }
  [class*="timeline-wrapper__label-"] {
    position: absolute;
    left: 50%;
    @include transform(translateX(-50%));
    width: 150px;
    @extend %text-center;
    @extend %base-color;
    @extend %text--white;
    padding: rem(3px) rem(10px);
    @extend %z-index-p;
    @include border-radius(3px); }
  &__label-top {
    top: -30px; }
  &__label-bottom {
    bottom: -30px; } }
.timeline-card {
  width: calc(50% - 120px);
  @extend %bg--white;
  padding: rem(20px);
  @extend %bs-8;
  margin-bottom: rem(70px);
  @extend %position-relative;
  box-shadow: 0 3px 15px rgba($base-color-two, 0.08);
  @extend %z-index-p;
  @include media(1199px) {
    width: calc(50% - 100px); }
  @include media(991px) {
    width: 100%;
    text-align: center;
    padding: rem(15px); }
  &:nth-child(odd) {
    margin-top: -70px;
    @include media(991px) {
      margin-top: 0; } }
  &:nth-child(even):not(:nth-child(2)) {
    margin-top: 0; }
  &:nth-child(even) {
    margin-top: rem(70px);
    &::before {
      left: -131px;
      right: auto;
      @include media(1199px) {
        left: -110px; }
      @include media(991px) {
        left: 50%;
        margin-left: -10px; } }
    &::after {
      right: auto;
      left: -8px;
      @include media(991px) {
        left: 50%;
        margin-left: -9px; } }
    .timeline-card__label {
      right: auto;
      left: -90px;
      @include media(1199px) {
        left: -80px; }
      &::before {
        right: auto;
        left: -35px;
        @include media(1199px) {
          left: -22px; } } } }
  &::after {
    @extend %position-absolute;
    content: '';
    top: 30px;
    right: -8px;
    width: 18px;
    height: 18px;
    @extend %bg--white;
    @include transform(rotate(45deg));
    @include border-radius(2px);
    @include media(991px) {
      top: -8px;
      right: 50%;
      margin-right: -9px; } }
  &::before {
    @extend %position-absolute;
    content: '';
    width: 20px;
    height: 20px;
    right: -131px;
    top: 27px;
    @extend %bs-50;
    @extend %bg--white;
    border: 4px solid $base-color;
    @extend %z-index-p;
    @include media(1199px) {
      right: -110px;
      top: 25px; }
    @include media(991px) {
      top: -40px;
      right: 50%;
      margin-right: -10px; } }
  &__label {
    width: 70px;
    @extend %text-center;
    @extend %position-absolute;
    top: 20px;
    right: -90px;
    @extend %base-color;
    @extend %text--white;
    font-weight: 600;
    padding: rem(2px) rem(10px);
    @include border-radius(3px);
    @include media(1199px) {
      font-size: rem(14px);
      width: 60px;
      right: -80px; }
    @include media(991px) {
      position: static;
      background-color: #fff;
      color: $base-color;
      font-weight: 700;
      margin-bottom: rem(15px);
      font-size: rem(16px); }
    &::before {
      @extend %position-absolute;
      content: '';
      top: 50%;
      right: -35px;
      width: 35px;
      height: 1px;
      @extend %base-color;
      @include media(1199px) {
        right: -22px;
        width: 22px; }
      @include media(991px) {
        display: none; } } }
  &__content {
    padding-top: rem(25px);
    padding-bottom: rem(10px); } }
/* timeline section css end */

/* gallery-thumb css start */
.gallery-thumb {
  height: 380px;
  @extend %overflow-hidden;
  @include media(1199px) {
    height: 250px; }
  @include media(991px) {
    height: 210px; }
  @include media(767px) {
    height: 280px; }
  @include media(480px) {
    height: 220px; }
  img {
    @extend %w-100;
    height: 100%;
    @extend %obj-fit; } }
/* gallery-thumb css end */

/* skill section css start */
.skill-card {
  @extend %text-center;
  padding: 0 rem(30px) rem(30px) rem(30px);
  border: 1px solid rgba(#fff, 0.2);
  @extend %transition;
  margin-top: 30px;
  @include media(1399px) {
    height: 80%; }
  &:hover {
    @extend %base-color;
    box-shadow: 0 3px 5px 2px rgba($base-color, 0.35); }
  &:hover & {
    &__icon {
      @extend %bg--white;
      @extend %text--base;
      box-shadow: 0 3px 5px 1px rgba(#000, 0.15);
      @include transform(translateY(-20px)); }
    &__content {
      p {
        @extend %text--white; } }
    &__title {
      color: #fff !important; } }
  &__icon {
    width: 70px;
    height: 70px;
    @extend %bs-50;
    border: 1px solid rgba(#fff, 0.2);
    display: inline-flex;
    @extend %justify-center;
    @extend %align-center;
    font-size: rem(28px);
    @extend %text--white;
    margin-top: -25px;
    @extend %base-color-two;
    @extend %transition;
    @include transform(translateY(-12px)); }
  &__content {
    @extend %transition;
    margin-top: 15px;
    p {
      @extend %transition; } }
  &__title {
    @extend %transition; } }
/* skill section css end */

/* hosting feature section css start */
.hosting-feature-item {
  &:nth-of-type(3n + 2) {
    .hosting-feature-card {
      &__icon {
        border-color: #673ab7;
        color: #673ab7; } } }
  &:nth-of-type(3n + 3) {
    .hosting-feature-card {
      &__icon {
        border-color: #35d0ba;
        color: #35d0ba; } } }
  &:nth-of-type(3n + 4) {
    .hosting-feature-card {
      &__icon {
        border-color: #ff5200;
        color: #ff5200; } } } }

.hosting-feature-card {
  padding: rem(30px) rem(20px);
  @extend %bg--white;
  @extend %text-center;
  height: 100%;
  @extend %bs-8;
  box-shadow: 0 5px 25px rgba(#000, 0.02);
  @extend %transition;
  &:hover {
    box-shadow: 0 10px 25px rgba(#000, 0.05);
    @extend %trans-y; }
  &:hover & {
    &__icon {
      background-color: currentColor;
      box-shadow: 0 5px 15px rgba(#000, 0.15);
      i {
        color: #fff; } } }
  &__icon {
    width: 80px;
    height: 80px;
    border: 1px solid $base-color;
    @extend %bs-50;
    display: inline-flex;
    @extend %align-center;
    @extend %justify-center;
    @extend %text--base;
    @extend %transition;
    i {
      font-size: rem(32px);
      @extend %transition; } }
  &__content {
    margin-top: rem(20px); }
  &__title {
    @include media(1399px) {
      font-size: rem(20px); } } }
/* hosting feature section css end */


/* hosting package section css start */
.package-card {
  @extend %bg--white;
  @extend %bs-8;
  @extend %transition;
  box-shadow: 0 10px 35px rgba(#000, 0.05);
  &:hover {
    @extend %trans-y; }
  span, p , li {
    color: $para-color; }
  &__header {
    @extend %d-flex;
    justify-content: space-between;
    @extend %base-color-two;
    padding: rem(30px);
    @include border-radius(8px 8px 0 0);
    border: 2px solid #fff;
    border-bottom: none;
    @include media(1199px) {
      padding: rem(20px); }
    span {
      font-weight: 500; }
    span {
      color: #f0f8ffa1; }
    .right {
      padding: rem(8px) rem(20px);
      @extend %bs-8;
      background-color: rgba(#fff, 0.15);
      display: inline-block;
      @extend %text-center; } }
  &__title {
    color: $base-color !important; }
  &__price {
    font-family: $heading-font;
    @extend %text--white;
    font-size: rem(24px);
    line-height: 1.2;
    font-weight: 700; }
  &__body {
    padding: rem(25px);
    @include media(1199px) {
      padding: rem(20px); } }
  &__list {
    padding-left: rem(30px);
    @extend %d-inline-block;
    text-align: left;
    li {
      padding: rem(8px) 0;
      font-size: rem(15px);
      @extend %position-relative;
      &::before {
        @extend %position-absolute;
        content: "\f00c";
        width: 18px;
        height: 18px;
        background-color: rgba($base-color, 0.15);
        @extend %text--base;
        @extend %bs-50;
        font-size: rem(9px);
        @extend %d-flex;
        @extend %justify-center;
        @extend %align-center;
        left: -30px;
        top: 10px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900; } } } }
/* hosting package section css end */

/* hosting key feature section css start */
.hosting-feature-card-two {
  padding: rem(30px) rem(35px);
  @extend %bg--white;
  @extend %bs-8;
  box-shadow: 0 3px 8px rgba(#000, 0.05);
  @extend %text-center;
  @extend %transition;
  height: 100%;
  @include media(1399px) {
    padding: rem(30px) rem(25px); }
  &:hover {
    box-shadow: 0 10px 25px rgba(#000, 0.07);
    @extend %trans-y; }
  .icon {
    width: 85px;
    height: 85px;
    display: inline-flex;
    @extend %justify-center;
    @extend %align-center;
    @extend %bs-50;
    @extend %base-color;
    @extend %text--white;
    box-shadow: 0 8px 20px rgba($base-color, 0.45);
    i {
      font-size: rem(36px); } }
  .content {
    margin-top: rem(25px); } }
/* hosting key feature section css end */

/* service section css start */
.service-single {
  &:nth-child(even) {
    @extend %section-bg; }
  &:nth-child(even) & {
    &__thumb {
      order: -1; } }
  &:nth-child(odd) & {
    &__thumb {
      @include media(991px) {
        order: -1; } } }
  .title {
    @include media(1199px) {
      font-size: rem(28px); }
    @include media(575px) {
      font-size: rem(24px); }
    @include media(480px) {
      font-size: rem(22px); } } }
/* service section css end */

/* data center location section css start */
.data-center-wrapper {
  padding: rem(30px);
  @extend %bg--white;
  @extend %bs-10;
  box-shadow: 0 30px 45px rgba($base-color-two, 0.05);
  border: 1px solid $border-color;
  @include media(420px) {
    padding: rem(20px); } }
/* data center location section css end */

/* content section css start */
.content-block {
  & + & {
    margin-top: rem(40px); }
  p {
    margin-top: rem(15px); } }
/* content section css end */

/* job-card css start */
.job-card {
  padding: rem(40px);
  @extend %base-color-two;
  @extend %bs-8;
  box-shadow: 0 3px 15px rgba(#000, 0.025);
  @extend %d-flex;
  @extend %align-center;
  @include media(767px) {
    padding: rem(25px); }
  & + & {
    margin-top: rem(30px); }
  &__left {
    width: calc(100% - 150px);
    padding-right: 30px;
    border-right: 1px solid rgba(#fff, 0.25);
    @include media(575px) {
      width: 100%;
      text-align: center;
      padding-right: 0;
      border-right: none; } }
  &__right {
    width: 150px;
    text-align: right;
    @include media(575px) {
      width: 100%;
      text-align: center;
      margin-top: rem(25px); } }
  &__category {
    padding: rem(2px) rem(15px);
    // background-color: rgba($base-color, 0.3)
    border: 1px solid $base-color;
    @extend %bs-5;
    display: inline-flex;
    @extend %align-center;
    font-size: rem(14px);
    @extend %text--base;
    i {
      font-size: rem(18px);
      margin-right: rem(5px); } }
  &__title {
    @extend %text--white; }
  &__meta {
    margin: rem(-4px) rem(-15px);
    li {
      padding: rem(4px) rem(15px);
      @extend %position-relative;
      color: #d1d5ff;
      @include media(991px) {
        font-size: rem(14px); }
      &:last-child {
        &::after {
          display: none; } }
      &::after {
        @extend %position-absolute;
        content: '';
        width: 7px;
        height: 7px;
        background-color: #d1d5ffa1;
        @extend %bs-50;
        right: -4px;
        top: 15px; } } } }
/* job-card css end */

/* work proccess section css start */
.work-proccess-area {
  @extend %position-relative;
  @extend %z-index-p;
  .wave-line {
    @extend %position-absolute;
    top: -30px;
    @extend %z-index-c;
    @include media(1399px) {
      top: -20px; }
    @include media(1199px) {
      top: 0; }
    @include media(991px) {
      top: 10px; }
    @include media(767px) {
      top: 45%;
      @include transform(rotate(90deg)); } } }

.work-proccess-card {
  @extend %text-center;
  &:hover & {
    &__icon {
      @extend %bg--white;
      @extend %text--base;
      @extend %trans-y; } }
  &__icon {
    width: 120px;
    height: 120px;
    @extend %base-color;
    @extend %text--white;
    @extend %bs-50;
    @extend %d-inline-flex;
    @extend %justify-center;
    @extend %align-center;
    font-size: rem(40px);
    @extend %transition;
    @include media(991px) {
      width: 100px;
      height: 100px; } }
  &__content {
    margin-top: rem(25px); }
  &__title {
    @include media(991px) {
      font-size: rem(20px); } } }
/* work proccess section css end */

/* how work section css start */
.how-work-card {
  padding: rem(25px);
  @extend %bg--white;
  box-shadow: 0 5px 15px rgba(#000, 0.05);
  @extend %bs-8;
  border: 1px solid lighten($border-color, 3%);
  @extend %transition;
  height: 100%;
  @include media(1199px) {
    padding: rem(15px); }
  @include media(767px) {
    padding: rem(25px); }
  @include media(380px) {
    padding: rem(15px); }
  &:hover {
    box-shadow: 0 10px 35px rgba(#000, 0.05);
    @extend %trans-y; }
  &__icon {
    margin-bottom: rem(30px); }
  &__title {
    margin-bottom: rem(8px); } }
/* how work section css end */
