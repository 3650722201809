/* account section css start */
.account-section {
  min-height: 100vh;
  @extend %d-flex;
  .input-group select {
    padding: rem(5px); }
  p, span {
    color: #d1d5ff; }
  .left, .right {
    min-height: 100vh;
    @extend %d-flex;
    flex-flow: column;
    @extend %align-center;
    @include media(767px) {
      min-height: auto; } }
  .left {
    width: 70%;
    @extend %justify-center;
    @extend %position-relative;
    @extend %z-index-p;
    @include media(1399px) {
      width: 60%; }
    @include media(1199px) {
      padding: rem(70px) rem(40px); }
    @include media(767px) {
      width: 100%; }
    @include media(575px) {
      padding: rem(50px) rem(30px); }
    &::before {
      @extend %positionTwo;
      @extend %base-color-two;
      opacity: 0.65;
      @extend %z-index-c; }
    .left-inner {
      width: 650px;
      @include media(1199px) {
        width: 100%; }
      .title {
        @include media(420px) {
          font-size: rem(22px); } } } }
  .right {
    width: 30%;
    @extend %base-color-two;
    padding: rem(80px) rem(50px);
    justify-content: space-between;
    @extend %position-relative;
    @extend %z-index-p;
    @include media(1399px) {
      width: 40%; }
    @include media(1199px) {
      padding: rem(60px) rem(30px); }
    @include media(767px) {
      width: 100%; }
    @include media(575px) {
      padding: rem(50px) rem(30px); }
    .el {
      @extend %position-absolute;
      bottom: 0;
      left: 0;
      @extend %z-index-c;
      opacity: 0.1; }
    .middle {
      padding: rem(30px) 0; } }
  &.style--two {
    .left {
      width: 60%;
      @include media(1650px) {
        width: 50%; }
      @include media(1399px) {
        width: 45%;
        padding: rem(70px) rem(40px); }
      @include media(991px) {
        width: 100%;
        min-height: auto; }
      .left-inner {
        @include media(1399px) {
          width: 100%; } } }
    .right {
      width: 40%;
      @include media(1650px) {
        width: 50%; }
      @include media(1399px) {
        width: 55%; }
      @include media(991px) {
        width: 100%;
        min-height: auto; } }
    .account-form {
      .form--control {
        @include media(1199px) {
          font-size: rem(15px); } } } } }

.account-form {
  label {
    @extend %text--white; }
  .form--control {
    background-color: transparent;
    @include placeholder(rgba(#fff, 0.65));
    border-color: rgba(#fff, 0.25);
    color: #fff;
    &:focus {
      color: #fff; } } }

.involde-logo-slider {
  img {
    @extend %d-inline-block;
    @extend %w-100; }
  .slick-list {
    margin: 0 rem(-10px); }
  .single-slide {
    padding: 0 rem(10px); }
  .involde-logo {
    @extend %base-color-two;
    @extend %bs-5;
    @extend %overflow-hidden; } }
/* account section css end */
