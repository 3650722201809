@include keyframes(outer-ripple) {
  0% {
    @include transform(scale(1));
    opacity: 0.5; }
  80% {
    @include transform(scale(1.5));
    opacity: 0; }
  100% {
    @include transform(scale(2.5));
    opacity: 0; } }

@include keyframes(inner-ripple) {
  0% {
    @include transform(scale(1));
    opacity: 0.5; }
  30% {
    @include transform(scale(1));
    opacity: 0.5; }
  100% {
    @include transform(scale(1.5));
    opacity: 0; } }
