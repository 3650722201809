/* blog details section css start */
.post-meta {
  margin: rem(-3px) rem(-10px);
  li {
    font-weight: 600;
    font-size: rem(15px);
    padding: rem(3px) rem(10px); } }

.blog-details {
  &__title {
    @include media(1399px) {
      font-size: rem(32px); }
    @include media(767px) {
      font-size: rem(28px); }
    @include media(575px) {
      font-size: rem(24px); } }
  &__thumb {
    @extend %bs-8;
    @extend %overflow-hidden; }
  &__content {
    margin-top: rem(30px); } }
.post-share {
  padding: rem(25px);
  border-top: 1px solid darken($border-color, 3%);
  border-bottom: 1px solid darken($border-color, 3%);
  .caption {
    font-weight: 600; }
  li:not(.caption) {
    padding: rem(3px) rem(10px);
    a {
      color: $para-color; } } }
/* blog details section css end */
