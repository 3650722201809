/* spacing css start */
.pt-100 {
  padding-top: rem(100px);
  @include media(1199px) {
    padding-top: rem(80px); }
  @include media(991px) {
    padding-top: rem(70px); }
  @include media(767px) {
    padding-top: rem(60px); }
  @include media(575px) {
    padding-top: rem(50px); } }
.pb-100 {
  padding-bottom: rem(100px);
  @include media(1199px) {
    padding-bottom: rem(80px); }
  @include media(991px) {
    padding-bottom: rem(70px); }
  @include media(767px) {
    padding-bottom: rem(60px); }
  @include media(575px) {
    padding-bottom: rem(50px); } }

.pt-50 {
  padding-top: rem(50px); }
.pb-50 {
  padding-bottom: rem(50px); }
/* spacing css end */
