/* pagination css start */
.pagination {
  margin: rem(-3px) rem(-5px);
  flex-wrap: wrap;
  .page-item {
    padding: rem(3px) rem(5px);
    &.active {
      .page-link {
        @extend %base-color;
        @extend %text--white;
        border-color: $base-color; } }
    .page-link {
      width: rem(40px);
      height: rem(40px);
      @extend %bg--white;
      display: flex;
      justify-content: center;
      align-items: center;
      @include border-radius(5px);
      border: 1px solid rgba(#000, 0.15);
      color: $para-color;
      &:hover {
        @extend %base-color;
        border-color: $base-color;
        color: #fff; } } } }
/* pagination css end */
