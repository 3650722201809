/* blog section css start */
.blog-post {
  $root: &;
  padding: rem(10px);
  @extend %bg--white;
  @extend %bs-8;
  box-shadow: 0 5px 25px rgba($base-color-two, 0.1);
  @extend %transition;
  &:hover {
    box-shadow: 0 10px 25px rgba($base-color-two, 0.15);
    #{$root}__thumb {
      img {
        @include transform(scale(1.1)); } } }
  &__thumb {
    @extend %bs-5;
    @extend %overflow-hidden;
    img {
      @extend %transition; } }
  &__content {
    padding: rem(20px) rem(10px) rem(10px) rem(10px); }
  &__title {
    font-size: rem(20px);
    @include media(480px) {
      font-size: rem(18px); }
    a {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @extend %overflow-hidden;
      text-overflow: ellipsis; } }
  &__description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @extend %overflow-hidden;
    text-overflow: ellipsis; } }
/* blog section css end */
