/* cart section css start */
.cart-wrapper {
  box-shadow: 0 5px 30px rgba(#000, 0.05);
  @extend %bs-5;
  @extend %overflow-hidden;
  &__header {
    padding: rem(10px) rem(20px);
    @extend %base-color-two; }
  &__body {
    @extend %bg--white;
    padding: rem(10px) rem(20px); }
  &__footer {
    @extend %d-flex;
    justify-content: space-between;
    padding: rem(20px);
    @extend %bg--white;
    border-top: 1px solid $border-color;
    @extend %align-center;
    .left {
      width: 350px;
      @include media(991px) {
        width: 280px; }
      @include media(767px) {
        width: 220px; }
      @include media(575px) {
        width: 100%; } }
    .right {
      width: 450px;
      text-align: right;
      @include media(991px) {
        width: calc(100% - 280px); }
      @include media(767px) {
        width: calc(100% - 220px); }
      @include media(575px) {
        width: 100%;
        text-align: center;
        margin-top: rem(15px); }
      .btn {
        @include media(575px) {
          width: 100%; } } } } }
.cart-total {
  font-family: $heading-font;
  font-size: rem(22px);
  @include media(767px) {
    font-size: rem(20px); }
  &__caption {
    color: $para-color;
    font-weight: 500;
    margin-right: 15px; }
  &__amount {
    color: $heading-color;
    font-weight: 700; } }


.cart-single {
  @extend %d-flex;
  @extend %align-center;
  padding: rem(15px) 0;
  border-top: 1px solid lighten($border-color , 3%);
  &:first-child {
    border-top: none; }
  &__product {
    width: calc(100% - 100px);
    @extend %d-flex;
    @extend %align-center;
    @include media(480px) {
      width: 100%; }
    .thumb {
      width: 60px; }
    .content {
      width: calc(100% - 60px);
      padding-left: rem(20px); }
    .title {
      @include media(420px) {
        font-size: rem(16px); } } }
  &__action {
    width: 100px;
    text-align: right;
    @include media(480px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px; } }
  .cart-price {
    font-family: $heading-font;
    font-weight: 700;
    font-size: rem(22px);
    color: $heading-color;
    line-height: 1.5;
    @include media(420px) {
      font-size: rem(18px); } } }

.cart-delete-btn {
  padding: 0;
  background-color: transparent;
  font-size: rem(22px);
  line-height: 1;
  margin-top: rem(5px);
  color: $danger; }

.cupon-form {
  @extend %d-flex;
  .form--control {
    width: calc(100% - 80px);
    height: 45px;
    font-size: 15px; }
  button {
    width: 80px;
    @extend %base-color;
    @extend %text--white;
    margin-left: -1px;
    @include border-radius(0 3px 3px 0); } }

/* cart section css end */
