/* dashboard section css start */
.dashboard-top {
  @extend %section-bg;
  z-index: 9;
  @include media(991px) {
    padding-bottom: 10px; } }

.user-area {
  padding: rem(40px) 0;
  @include media(991px) {
    padding: rem(30px) 0; } }

.user-card {
  @extend %d-flex;
  @extend %align-center;
  &__thumb {
    width: 80px;
    height: 80px;
    @extend %overflow-hidden;
    @extend %bs-5;
    border: 2px solid rgba(#fff, 0.35);
    @include media(420px) {
      width: 70px;
      height: 70px; } }
  &__content {
    padding-left: rem(20px); } }

.purchased-amount {
  font-family: $heading-font;
  font-weight: 700;
  font-size: 28px;
  color: $heading-color;
  line-height: 1.3; }

.user-menu-open {
  background-color: transparent;
  font-weight: 600;
  @extend %w-100;
  @include border-radius(5px 5px 0 0);
  padding: rem(8px) rem(10px);
  display: none;
  @extend %align-center;
  justify-content: space-between;
  @include media(991px) {
    display: flex;
    @include border-radius(5px); }
  .left {
    span {
      @extend %text--white; }
    i {
      font-size: rem(18px); } }
  .right {
    i {
      @extend %text--base;
      font-size: rem(22px); } } }

.user-menu-wrapper {
  @extend %transition;
  z-index: 999999;
  &.active {
    right: 0; }
  @include media(991px) {
    position: fixed;
    top: 0;
    right: -105%;
    z-index: 999999;
    background-color: $base-color-two;
    box-shadow: -5px 0 5px rgba(#000, 0.15);
    min-height: 100vh;
    width: 240px;
    padding-top: rem(20px); }
  .user-menu-close {
    @extend %position-absolute;
    top: 0;
    right: 0;
    background-color: $danger;
    @extend %text--white;
    display: none;
    @include media(991px) {
      display: inline-block; } } }

.user-menu {
  @extend %d-flex;
  @include media(991px) {
    display: block; }
  li {
    @include media(991px) {
      border-bottom: 1px solid rgba(#fff, 0.15); }
    &.active {
      a {
        @extend %text--base;
        &::after {
          display: block;
          @include media(991px) {
            display: none; } } } }
    a {
      font-size: rem(15px);
      color: darken(#fff, 15%);
      font-weight: 600;
      padding: rem(8px) rem(20px);
      @include border-radius(5px 5px 0 0);
      @extend %transition;
      @extend %position-relative;
      @include media(1199px) {
        padding: rem(7px) rem(15px); }
      @include media(991px) {
        display: block;
        color: #c2c6f2; }
      &:hover {
        @extend %text--base; }
      &::after {
        @extend %position-absolute;
        content: '';
        bottom: -1px;
        left: 0;
        @extend %w-100;
        height: 3px;
        @extend %base-color;
        display: none; } } } }

.d-widget {
  padding: rem(20px) rem(15px);
  box-shadow: 0 5px 10px rgba(#000, 0.08);
  @extend %bg--white;
  @extend %bs-5;
  @extend %d-flex;
  @extend %align-center;
  @extend %position-relative;
  @extend %z-index-p;
  @extend %transition;
  @extend %overflow-hidden;
  &:hover {
    @extend %trans-y; }
  &::before {
    @extend %position-absolute;
    content: '';
    bottom: -40px;
    right: -25px;
    width: 52px;
    height: 85px;
    background-color: #0F67F4;
    opacity: 0.1;
    @extend %z-index-c;
    @include transform(rotate(45deg)); }
  &__icon {
    width: 60px;
    height: 60px;
    @extend %bs-10;
    background-color: #0F67F4;
    box-shadow: 0 8px 15px rgba(#0F67F4, 0.35);
    @extend %d-flex;
    @extend %align-center;
    @extend %justify-center;
    @extend %text--white;
    font-size: rem(32px);
    @extend %z-index-c; }
  &__content {
    width: calc(100% - 60px);
    padding-left: rem(20px); }
  &__amount {
    font-weight: 500;
    font-size: rem(24px);
    @include media(1399px) {
      font-size: rem(20px); }
    @include media(1199px) {
      font-size: rem(24px); }
    @include media(575px) {
      font-size: rem(20px); } }
  &__btn {
    font-size: rem(12px);
    color: $para-color;
    border: 1px solid $border-color;
    @include border-radius(999px);
    padding: 0 rem(10px);
    @extend %transition;
    &:hover {
      @extend %base-color;
      @extend %text--white;
      border-color: $base-color; }
    &.position-absolute {
      top: 5px;
      right: 5px; } } }

[class*="col"] {
  &:nth-of-type(4n + 2) {
    .d-widget {
      &::before {
        background-color: #7367f0; }
      &__icon {
        background-color: #7367f0;
        box-shadow: 0 8px 15px rgba(#7367f0, 0.35); } } }
  &:nth-of-type(4n + 3) {
    .d-widget {
      &::before {
        background-color: #1e9ff2; }
      &__icon {
        background-color: #1e9ff2;
        box-shadow: 0 8px 15px rgba(#1e9ff2, 0.35); } } }
  &:nth-of-type(4n + 4) {
    .d-widget {
      &::before {
        background-color: #4caf50; }
      &__icon {
        background-color: #4caf50;
        box-shadow: 0 8px 15px rgba(#4caf50, 0.35); } } } }

.d-widget-solid {
  @extend %d-flex;
  @extend %align-center;
  justify-content: space-between;
  background-color: #0F67F4;
  box-shadow: 0 8px 15px rgba(#0F67F4, 0.35);
  padding: rem(15px);
  @extend %bs-8;
  @extend %position-relative;
  @extend %overflow-hidden;
  @extend %transition;
  @extend %z-index-p;
  &:hover {
    @extend %trans-y; }
  i {
    @extend %position-absolute;
    top: -5px;
    right: -5px;
    color: #fff;
    opacity: 0.15;
    font-size: rem(72px);
    line-height: 1;
    opacity: 0.15;
    @extend %z-index-c; }
  &__left {
    padding: rem(7px) rem(10px); }
  &__amount {
    font-weight: 500;
    @extend %text--white; }
  &__caption {
    color: darken(#fff, 0.7);
    font-size: rem(14px); }
  &__right {
    padding: rem(7px) rem(10px); }
  &__btn {
    font-size: rem(12px);
    padding: rem(1px) rem(10px);
    @extend %bg--white;
    @include border-radius(3px);
    color: $para-color; } }

[class*="col"] {
  &:nth-of-type(3n + 2) {
    .d-widget-solid {
      background-color: #7367f0; } }
  &:nth-of-type(3n + 3) {
    .d-widget-solid {
      background-color: #1e9ff2; } } }


.user-profile {
  padding: rem(30px);
  @extend %section-bg;
  @extend %bs-5;
  @include media(1199px) {
    padding: rem(20px); } }

.profile-thumb {
  position: relative;
  width: rem(200px);
  height: rem(200px);
  @include border-radius(15px);
  display: inline-flex;
  .profilePicPreview {
    width: rem(200px);
    height: rem(200px);
    @include border-radius(15px);
    display: block;
    border: 2px solid #ffffff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    background-size: cover;
    background-position: center; }
  .profilePicUpload {
    font-size: 0;
    opacity: 0; }
  .avatar-edit {
    position: absolute;
    right: 0;
    bottom: -10px;
    input {
      width: 0; }
    label {
      font-size: rem(12px);
      padding: rem(1px) rem(15px);
      @extend %base-color;
      @include border-radius(999px);
      @extend %text--white;
      cursor: pointer; } } }

.profile-info-list {
  li {
    padding: rem(14px) 0;
    @extend %d-flex;
    border-bottom: 1px dashed darken($border-color, 5%);
    font-size: rem(15px);
    @include media(575px) {
      font-size: rem(14px); }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0; }
    .caption {
      width: 40%;
      font-weight: 600;
      display: flex;
      align-items: center;
      i {
        font-size: rem(20px);
        width: 25px; } }
    .details {
      width: 60%; } } }

.no-data-wrapper {
  @extend %bs-8;
  padding: rem(40px);
  .no-data-thumb {
    max-width: 350px;
    display: inline-block;
    @include media(575px) {
      max-width: 100%; } }
  .title {
    font-weight: 400;
    margin-top: rem(20px);
    @include media(1199px) {
      font-size: rem(32px); }
    @include media(991px) {
      font-size: rem(24px); }
    @include media(480px) {
      font-size: rem(22px); }
    @include media(380px) {
      font-size: rem(18px); } } }

.deposit-method-footer,
.deposit-preview-list li {
  @extend %d-flex;
  @extend %align-center;
  .caption {
    width: 40%; }
  .value {
    width: 60%;
    text-align: right; } }

.deposit-preview-list {
  li {
    padding: rem(6px) 0;
    border-bottom: 1px dashed $border-color; } }

.support-add-btn {
  width: 50px;
  height: 50px;
  @extend %base-color;
  @extend %text--white;
  @extend %bs-5;
  font-size: rem(24px); }


// review area
.give-rating {
  clear: both;
  float: none;
  span {
    float: right;
    position: relative;
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 0; }
    label {
      cursor: pointer;
      display: inline-block;
      width: 30px;
      height: 30px;
      text-align: center;
      color: gray;
      font-size: 30px;
      margin-right: 5px;
      line-height: 30px; }
    &:hover {
      ~ span label, label {
        color: #F90; } }

    &.checked {
      label, ~ span label {
        color: #F90; } } } }
/* dashboard section css end */
