/* footer section css start */
.footer {
  @extend %base-color-two;
  p, li, span {
    color: #f0f8ffa1; }
  &__top {
    padding: rem(40px) 0;
    @include media(991px) {
      padding: rem(30px) 0; } }
  &__middle {
    padding: rem(70px) 0;
    @extend %position-relative;
    @include media(991px) {
      padding: rem(50px) 0; }
    &::before,
    &::after {
      @extend %position-absolute;
      content: '';
      left: 100px;
      right: 100px;
      height: 1px;
      @extend %footer-border;
      @include media(1500px) {
        left: 50px;
        right: 50px; }
      @include media(767px) {
        left: 15px;
        right: 15px; } }
    &::before {
      top:  0; }
    &::after {
      bottom: 0; } }
  &__bottom {
    padding: rem(30px) 0; } }

.brand-slider {
  .slick-list {
    margin: 0 rem(-10px); }
  .single-slide {
    padding: 0 rem(10px); } }
.brand-item {
  text-align: center; }

.footer-widget {
  &__title {
    font-size: rem(24px);
    @extend %text--white;
    @extend %position-relative;
    padding-bottom: rem(10px);
    @include media(1199px) {
      font-size: rem(20px); }
    &::before {
      @extend %position-absolute;
      content: '';
      left: 0;
      bottom: 0;
      height: 2px;
      width: 40px;
      @extend %base-color; } } }
.short-link-list {
  margin-top: rem(20px);
  li {
    @extend %footer-border;
    &:last-child {
      border-bottom: none; }
    a {
      padding: rem(8px) 0;
      display: block;
      color: #f0f8ffa1;
      @include media(1199px) {
        font-size: rem(15px); }
      &:hover {
        @extend %text--base; } } } }
.copyright-text {
  font-weight: 500;
  font-size: rem(16px); }

.footer-social-links {
  margin: rem(-3px) rem(-8px);
  li {
    padding: rem(3px) rem(8px);
    a {
      width: 40px;
      height: 40px;
      border: 1px solid rgba(#fff, 0.2);
      @extend %justify-center;
      @extend %align-center;
      font-size: rem(20px);
      @extend %bs-50;
      @extend %d-flex;
      @extend %transition;
      @extend %text--base;
      &:hover {
        @extend %base-color;
        @extend %text--white;
        border-color: transparent; } } } }
/* footer section css end */
