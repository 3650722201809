/* forum section css start */
.forum-card {
  padding: rem(20px);
  @extend %bs-5;
  @extend %bg--white;
  @extend %d-flex;
  align-items: center;
  border: 1px solid $border-color;
  @extend %transition;
  @include media(420px) {
    justify-content: center;
    text-align: center; }
  &:hover {
    @extend %trans-y;
    box-shadow: 0 5px 15px rgba(#000, 0.025);
    border-color: $base-color; }
  & + & {
    margin-top: rem(15px); }
  &__thumb {
    width: 60px;
    height: 60px;
    @extend %bs-50;
    @extend %overflow-hidden; }
  &__content {
    width: calc(100% - 80px);
    padding-left: rem(20px);
    @include media(575px) {
      width: 100%;
      padding-left: 0;
      margin-top: rem(15px); } }
  &__title {
    font-size: rem(18px); }
  &__meta {
    @extend %d-flex;
    margin: rem(-3px) rem(-15px);
    @include media(420px) {
      justify-content: center; }
    li {
      font-size: rem(14px);
      padding: rem(3px) rem(15px);
      i {
        font-size: rem(12px); }
      a {
        color: $para-color;
        &:hover {
          @extend %text--base; } } } }
  &__footer {
    @extend %w-100;
    @extend %d-flex;
    justify-content: space-between;
    @include media(420px) {
      justify-content: center; } } }
/* forum section css end */
