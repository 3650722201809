/* background color css start */
.bg--primary {
  background-color: $primary !important; }
.bg--secondary {
  background-color: $secondary !important; }
.bg--success {
  background-color: $success !important; }
.bg--danger {
  background-color: $danger !important; }
.bg--warning {
  background-color: $warning !important; }
.bg--info {
  background-color: $info !important; }
.bg--dark {
  background-color: $dark !important; }
.bg--light {
  background-color: $light !important; }
.bg--base {
  background-color: $base-color !important; }
/* background color css end */
