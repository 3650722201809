/* table css start */
.custom--table {
  thead {
    @extend %base-color-two;
    th {
      border-bottom: 1px solid rgba(#000, 0.05) !important;
      border-top: none;
      padding: rem(12px) rem(20px);
      @extend %text--white;
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
      vertical-align: middle;
      // color: #8898aa
      // background-color: #f6f9fc
      &:first-child {
        text-align: left;
        // +border-radius(8px 0 0 0)
 }        // padding-left: 0
      &:last-child {
        text-align: right;
        // +border-radius(0 8px 0 0)
 } } }        // padding-right: 0
  tbody {
    td {
      border-top: none;
      border-bottom: 1px solid lighten($border-color, 3%);
      padding: rem(15px) rem(20px);
      color: $para-color;
      text-align: center;
      vertical-align: middle;
      font-size: rem(14px);
      &:first-child {
        text-align: left; }
      &:last-child {
        text-align: right; } }
    tr {
      &:last-child {
        td {
          border-bottom: none; } } } } }
.table--striped {
  tbody {
    tr {
      &:nth-child(even) {
        @extend %section-bg; }
      td {
        border-bottom: none; } } } }
// table responsive
[data-label] {
  position: relative;
  &::before {
    position: absolute;
    content: attr(data-label);
    font-weight: 700;
    color: $heading-color;
    top: 0;
    left: 0;
    padding: rem(13px) rem(15px);
    display: none;
    font-size: rem(12px); } }
.table-responsive--md {
  @include media(991px) {
    thead {
      display: none; }
    tbody {
      tr {
        &:nth-child(even) {
          background-color: $section-bg; }
        &:last-child {
          td {
            border-bottom: 1px solid lighten($border-color, 3%);
            &:last-child {
              border-bottom: none; } } }
        td {
          padding-right: 15px;
          &:last-child {
            padding-right: 15px; } } } }
    tr {
      th, td {
        display: block;
        padding-left: 45%!important;
        text-align: right!important; } }
    [data-label] {
      &::before {
        display: block; } } } }
.table-responsive--sm {
  @include media(767px) {
    thead {
      display: none; }
    tbody {
      tr {
        &:nth-child(even) {
          background-color: $section-bg; }
        &:last-child {
          td {
            border-bottom: 1px solid lighten($border-color, 3%);
            &:last-child {
              border-bottom: none; } } }
        td {
          padding-right: 15px;
          &:last-child {
            padding-right: 15px; } } } }
    tr {
      th, td {
        display: block;
        padding-left: 45%!important;
        text-align: right!important; } }
    [data-label] {
      &::before {
        display: block; } } } }
*[class*="table-responsive--"] {
  &.data-label--none {
    @media (max-width: 1199px) {
      tr {
        th, td {
          padding-left: .75rem; } } } } }
/* table css end */
