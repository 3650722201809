/* hero section css start */
.hero {
  @extend %position-relative;
  padding-top: rem(360px);
  margin-bottom: rem(161px);
  @extend %z-index-p;
  @include media(1399px) {
    padding-top: rem(280px); }
  @include media(1199px) {
    margin-bottom: rem(120px); }
  @include media(991px) {
    padding-top: rem(200px); }
  @include media(510px) {
    margin-bottom: rem(80px); }
  @include media(360px) {
    padding-top: rem(180px); }
  &::before {
    @extend %positionTwo;
    @extend %base-color-two;
    @extend %z-index-c;
    opacity: 0.65; }
  &__subtitle {
    font-size: rem(100px);
    line-height: 1.3;
    @include media(1399px) {
      font-size: rem(90px); }
    @include media(1199px) {
      font-size: rem(72px); }
    @include media(1199px) {
      font-size: rem(62px); }
    @include media(767px) {
      font-size: rem(52px); }
    @include media(510px) {
      font-size: rem(42px); }
    @include media(420px) {
      font-size: rem(36px); }
    @include media(360px) {
      font-size: rem(32px); } }
  &__title {
    font-size: rem(80px);
    @include media(1399px) {
      font-size: rem(72px); }
    @include media(1199px) {
      font-size: rem(56px); }
    @include media(991px) {
      font-size: rem(48px); }
    @include media(767px) {
      font-size: rem(42px); }
    @include media(510px) {
      font-size: rem(34px); }
    @include media(420px) {
      font-size: rem(28px); }
    @include media(360px) {
      font-size: rem(24px); } }
  &__bottom {
    margin-top: rem(100px);
    @include transform(translateY(161px));
    @include media(1399px) {
      margin-top: 0; }
    @include media(1199px) {
      @include transform(translateY(120px)); }
    @include media(510px) {
      @include transform(translateY(80px)); } }
  &__content-area {
    padding: rem(80px) rem(90px) rem(70px) rem(40px);
    @extend %base-color;
    @extend %bs-10;
    animation: colorchange 50s;
    -webkit-animation: colorchange 20s linear infinite;
    position: relative;
    @include media(1399px) {
      padding: rem(60px) rem(50px) rem(50px) rem(40px); }
    @include media(991px) {
      padding: rem(40px) rem(50px); }
    @include media(767px) {
      padding: rem(30px); }
    @include media(360px) {
      padding: rem(20px) rem(15px); }
    &::after {
      @extend %position-absolute;
      content: '';
      top: 106px;
      left: calc((100% / 12) * 5 + 35px);
      height: calc(100% - 200px);
      width: 2px;
      @extend %bg--white;
      @include media(1399px) {
        top: 80px;
        height: calc(100% - 140px); }
      @include media(991px) {
        display: none; } }
    .title {
      font-size: rem(42px);
      @extend %position-relative;
      @include media(1199px) {
        font-size: rem(32px); }
      @include media(991px) {
        padding-bottom: rem(15px);
        &::after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 50%;
          width: 120px;
          height: 2px;
          background-color: #fff;
          margin-left: -60px; } }
      @include media(767px) {
        font-size: rem(28px); }
      @include media(510px) {
        font-size: rem(24px); }
      @include media(420px) {
        font-size: rem(20px); }
      @include media(360px) {
        font-size: rem(18px); } }
    .description {
      font-size: rem(18px);
      @include media(1199px) {
        font-size: rem(16px); } } } }

@keyframes colorchange {
  0% {
    background: #2dbb6c; }
  25% {
    background: #ff5200; }
  50% {
    background: #10375c; }
  75% {
    background: #F44336; }
  0% {
    background: #2dbb6c; } }
/* hero section css end */

/* marketplace-hero css strat */
.marketplace-hero {
  padding-top: rem(100px);
  padding-bottom: rem(100px);
  @extend %position-relative;
  @extend %z-index-p;
  &::before {
    @extend %positionTwo;
    @extend %base-color-two;
    @extend %z-index-c;
    opacity: 0.55; }
  &__title {
    font-size: rem(36px);
    font-weight: 400; } }

.form-text-animation {
  display: flex;
  @extend %position-absolute;
  top: 50%;
  left: rem(20px);
  @include transform(translateY(-50%));
  color: lighten(#777, 5%);
  @extend %z-index-c; }
.hero-search-form {
  @extend %position-relative;
  @extend %z-index-p;
  @extend %bg--white;
  @extend %bs-5;
  .form--control {
    background-color: transparent;
    height: 55px;
    &.hascontent ~ .form-text-animation,
    &:focus ~ .form-text-animation {
      opacity: 0; } }
  &__btn {
    @extend %position-absolute;
    top: 7px;
    right: 7px;
    width: 100px;
    height: calc(100% - 14px);
    @extend %base-color;
    color: $heading-color;
    @extend %bs-5;
    z-index: 3; } }
/* marketplace-hero css end */

/* inner hero section css start */
.header.style--one ~ .main-wrapper .inner-hero {
  padding-top: 200px;
  @include media(991px) {
    padding-top: rem(170px); }
  @include media(575px) {
    padding-bottom: rem(50px) !important; } }
.inner-hero {
  padding: rem(80px) 0;
  @include media(991px) {
    padding: rem(70px) 0; }
  @extend %position-relative;
  @extend %z-index-p;
  &::before {
    @extend %positionTwo;
    @extend %base-color-two;
    opacity: 0.65;
    @extend %z-index-c; }
  &__title {
    font-size: rem(42px);
    @extend %text--white;
    @include media(991px) {
      font-size: rem(36px); }
    @include media(767px) {
      font-size: rem(32px); }
    @include media(767px) {
      font-size: rem(28px); } }
  p {
    color: #d1d5ff;
    margin-top: rem(8px); } }
/* inner hero section css end */
