/* contact section css start */
.contact-form {
  padding: rem(50px);
  @extend %bs-8;
  @extend %bg--white;
  box-shadow: 0 10px 35px rgba(#000, 0.05);
  @include media(575px) {
    padding: rem(30px); }
  @include media(420px) {
    padding: rem(20px); } }

.dropify-wrapper {
  .dropify-message {
    .file-icon {
      &::before {
        font-size: rem(32px); }
      p {
        font-size: rem(20px);
        @include media(575px) {
          font-size: rem(16px); } } } } }
.dropify-preview {
  .dropify-render {
    max-height: 100%;
    @extend %overflow-hidden;
    img {
      top: 0;
      @include transform(translate(0, 0) !important); } } }
/* contact section css end */
