@import "./_reset";
/* global css strat */
@import "./_color";
@import "./_bg";

.bg_img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.dark--overlay {
  @extend %position-relative;
  @extend %z-index-p;
  &::before {
    @extend %positionTwo;
    @extend %base-color-two;
    @extend %z-index-c;
    opacity: 0.85; } }

.z-index-2 {
  z-index: 2; }
.main-wrapper {
  @extend %position-relative; }

.obj-fit {
  @extend %obj-fit; }

a.text-white {
  &:hover {
    color: $base-color !important; } }

.section-header {
  margin-bottom: rem(55px);
  margin-top: rem(-6px);
  @include media(991px) {
    margin-bottom: rem(45px); }
  @include media(767px) {
    margin-bottom: rem(35px); }
  @include media(575px) {
    margin-bottom: rem(30px); }
  p {
    margin-top: rem(15px); } }
.section-title {
  font-size: rem(50px);
  @include media(1399px) {
    font-size: rem(44px); }
  @include media(1199px) {
    font-size: rem(42px); }
  @include media(991px) {
    font-size: rem(36px); }
  @include media(575px) {
    font-size: rem(32px); }
  @include media(480px) {
    font-size: rem(28px); } }

.section--bg {
  @extend %section-bg; }

.section--bg2 {
  background-color: $base-color-two; }

.dark--overlay, .section--bg2 {
  h1, h2, h3, h4, h5, h6 {
    @extend %text--white; }
  p, span, li {
    color: #c2c6f2; } }

.cmn-list {
  li + li {
    margin-top: rem(5px); }
  li {
    i {
      @extend %text--base; } } }
.dot--list {
  li +li {
    margin-top: 10px; }
  li {
    padding-left: 15px;
    @extend %position-relative;
    &::before {
      @extend %position-absolute;
      content: '';
      width: 7px;
      height: 7px;
      @extend %bs-50;
      @extend %base-color;
      left: 0;
      top: 9px; } } }

.number-list {
  counter-reset: list;
  li + li {
    margin-top: rem(13px); }
  li {
    @extend %position-relative;
    padding-left: 30px;
    &::before {
      @extend %position-absolute;
      counter-increment: list;
      content: counter(list);
      top: 3px;
      left: 0;
      width: 20px;
      height: 20px;
      @extend %base-color;
      @extend %text--white;
      @extend %bs-50;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rem(12px); } } }

.custom--accordion {
  .accordion-item + .accordion-item {
    margin-top: rem(20px); }
  .accordion-item {
    border: 1px solid $border-color;
    @include border-radius(5px);
    &:first-child {
      .accordion-button {
        border-top: none; } }
    &:last-child {
      .accordion-button {
        border-bottom: none; } } }
  .accordion-button {
    padding: rem(20px) rem(25px);
    @extend %bg--white;
    font-size: rem(18px);
    position: relative;
    text-align: left;
    @include media(575px) {
      font-size: rem(16px); }
    &::after {
      position: absolute;
      top: rem(20px);
      right: rem(13px);
      font-size: rem(17px);
      content: '\f107';
      font-family: 'Line Awesome Free';
      font-weight: 900;
      background-image: none;
      color: #000; }
    &:not(.collapsed) {
      background-color: $base-color;
      color: #fff;
      &::after {
        color: #fff; } }
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: transparent; } }
  .accordion-body {
    padding: rem(20px) rem(25px); } }

.cmn--nav-tabs {
  border-bottom: 1px solid $border-color;
  .nav-item {
    padding: rem(5px);
    .nav-link {
      color: $para-color;
      border: none;
      @extend %position-relative;
      @include media(380px) {
        padding: rem(5px) rem(10px); }
      &:hover {
        @extend %text--base; }
      &::after {
        @extend %position-absolute;
        content: '';
        bottom: -4px;
        left: 0;
        @extend %w-100;
        height: 2px;
        @extend %base-color;
        opacity: 0;
        @extend %transition; }
      &.active {
        background-color: transparent;
        @extend %text--base;
        &::after {
          opacity: 1; } } } } }


.scroll-to-top {
  height: 45px;
  width: 45px;
  position: fixed;
  bottom: 5%;
  right: 5%;
  display: none;
  z-index: 99999;
  cursor: pointer;
  @extend %text-center;
  border-radius: 50%;
  @extend %base-color;
  line-height: 48px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  .scroll-icon {
    font-size: rem(20px);
    color: #ffffff;
    display: inline-block; } }

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  @extend %w-100;
  height: 100%;
  @extend %base-color-two;
  z-index: 99999999;
  &__content {
    @extend %position-absolute;
    width: 150px;
    height: 150px;
    @extend %bs-50;
    // border: 3px solid rgba(#fff, 0.1)
    left: 50%;
    top: 50%;
    @include transform(translate(-50%, -50%));
    @extend %d-flex;
    justify-content: center;
    align-items: center; }
  &__text {
    font-size: rem(24px);
    font-weight: 700;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: url('../images/preloader-shape.png');
    background-position: 0 0;
    animation: serveja 4s linear infinite;
    -webkit-animation: serveja 4s linear infinite;
    -moz-animation: serveja 4s linear infinite;
    -ms-animation: serveja 4s linear infinite; }
  &__circle {
    @extend %position-absolute;
    top: -3px;
    left: -3px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    border: 3px solid transparent;
    border-top: 3px solid $base-color;
    border-right: 3px solid $base-color;
    border-radius: 50%;
    -webkit-animation: animateCircle 2s linear infinite;
    animation: animateCircle 2s linear infinite;
    &::before {
      @extend %position-absolute;
      content: '';
      bottom: 11px;
      right: 15px;
      width: 15px;
      height: 15px;
      box-shadow: 0 0 10px 1px $base-color;
      @extend %bs-50;
      @extend %base-color; } } }
@keyframes serveja {
  0% {
    background-position: 0 0; }
  25% {
    background-position: 250px 0; }
  50% {
    background-position: 500px 0; }
  75% {
    background-position: 250px 0; }
  100% {
    background-position: 0 0; } }

@keyframes animateCircle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
/* global css end */
