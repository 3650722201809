/* header css start */
.header {
  position: absolute;
  top: 0;
  left:  0;
  @extend %w-100;
  z-index: 9;
  &.menu-fixed {
    position: fixed !important;
    .header__top {
      display: none; }
    .header__bottom {
      @extend %base-color-two; } }
  &__top {
    @extend %base-color-two;
    padding: rem(13px) 0; }
  &__bottom {
    @include media(991px) {
      padding: rem(10px) 0; } }
  .site-logo {
    img {
      max-width: rem(250px);
      max-height: rem(75px);
      @include media(1199px) {
        max-width: rem(200px); }
      @include media(991px) {
        max-width: rem(170px); } } }
  .main-menu {
    @include media(991px) {
      padding: rem(15px) 0; }
    li {
      position: relative;
      @include media(991px) {
        border-bottom: 1px solid rgba(#ffffff, 0.15); }
      &:last-child {
        a {
          padding-right: 0; } }
      &.menu_has_children {
        position: relative;
        &.open {
          >.sub-menu {
            display: block; } }
        >a {
          padding-right: rem(25px);
          @include media(991px) {
            display: block; }
          &::before {
            position: absolute;
            content: "\f107";
            font-family: "Line Awesome Free";
            font-weight: 900;
            top: rem(28px);
            right: 0;
            color: #c2c6f2;
            @extend  %transition;
            @include media(991px) {
              display: block;
              top: rem(9px);
              color: #fff; } } }
        &:hover {
          >a::before {
            content: "\f106";
            color: $base-color; } } }
      a {
        font-family: $para-font;
        font-weight: 600;
        padding: rem(28px) rem(15px) rem(28px) 0;
        font-size: rem(15px);
        color: #c2c6f2;
        @include media(1199px) {
          padding: rem(28px) rem(5px) rem(28px) 0; }
        @include media(991px) {
          padding: rem(8px) 0;
          display: block; }
        &:hover,&:focus {
          color: $base-color; } }
      .sub-menu {
        position: absolute;
        width: 220px;
        top: 105%;
        left: 0;
        padding: rem(10px) 0;
        z-index: 9999;
        @extend %bg--white;
        box-shadow: 0px 5px 25px 2px rgba(0,0,0,0.1);
        @include border-radius(3px);
        @extend  %transition;
        opacity: 0;
        visibility: hidden;
        @include media(991px) {
          opacity: 1;
          visibility: visible;
          display: none;
          position: static;
          @include transition(none);
          width: 100%;
          background-color: $base-color-two;
          border: none; }
        li {
          // border-bottom: 1px solid rgba(#000036, 0.05)
          @include media(991px) {
            border-color: rgba(#fff, 0.15); }
          &:first-child {
            a {
              @include border-radius(5px 5px 0 0); } }
          &:last-child {
            border-bottom: none;
            a {
              @include border-radius(0 0 5px 5px); } }
          a {
            padding: rem(8px) rem(25px);
            display: block;
            color: $para-color;
            position: relative;
            font-size: rem(14px);
            text-transform: capitalize;
            @extend  %transition;
            @include media(991px) {
              color: #fff; }
            &:hover {
              // @extend %base-color
              @extend %text--base; } } }
        li+li {
          margin-left: 0; } }
      &:hover > .sub-menu {
        top: 100%;
        opacity: 1;
        visibility: visible; } }
    li+li {
      margin-left: rem(20px);
      @include media(1199px) {
        margin-left: rem(15px); }
      @include media(991px) {
        margin-left: 0; } }
    .sub-menu {
      li.menu_has_children {
        >a {
          &::before {
            top: 9px;
            right: 8px; } }
        > .sub-menu {
          left: 100%;
          top: 0;
          @include media(991px) {
            padding-left: 15px; } } } } }


  &__info-list {
    margin: rem(-3px) rem(-10px);
    li {
      padding: rem(3px) rem(10px);
      a {
        display: flex;
        @extend %align-center;
        color: #fff;
        font-size: rem(14px);
        i {
          font-size: rem(20px);
          margin-right: rem(6px);
          @extend %text--base; } } } }
  &.style--two {
    border-bottom: none;
    position: static;
    .site-logo {
      img {
        max-height: rem(35px); } }
    .header__top {
      padding: rem(10px) 0;
      border-top: 1px solid rgba(#fff, 0.15); }
    .header__bottom {
      @extend %base-color-two;
      .main-menu > li > a {
        padding: rem(20px) rem(15px) rem(20px) 0;
        @include media(991px) {
          padding: rem(10px) 0; } }
      .main-menu {
        li.menu_has_children {
          >a {
            &::before {
              font-size: rem(12px);
              top: 24px;
              right: -3px;
              @include media(991px) {
                top: 12px;
                right: 0; } } } } } } }
  &.position-static {
    .header__bottom {
      @extend %base-color-two;
      border-top: 1px solid rgba(#fff, 0.15); } }
  &__search-btn {
    background-color: transparent;
    padding: 0;
    @extend %text--white;
    font-size: rem(22px);
    margin-right: rem(20px);
    @include media(991px) {
      display: none; }
    i {
      &::before {
        @extend %transition; } }
    &.active {
      i {
        transform: rotate(0);
        &::before {
          content: "\f00d"; } } } } }

.nav-right {
  @extend %d-flex;
  @extend %position-relative;
  @include media(991px) {
    display: block; } }
.header-search-form {
  @extend %position-absolute;
  top: 110%;
  right: 120px;
  @extend %d-flex;
  width: 250px;
  opacity: 0;
  visibility: hidden;
  @extend %transition;
  z-index: 99;
  @include media(991px) {
    position: static;
    opacity: 1;
    visibility: visible;
    width: 100%;
    margin-bottom: rem(15px); }
  &.active {
    opacity: 1;
    visibility: visible;
    top: 100%; }
  .form--control {
    @include border-radius(5px 0 0 5px);
    height: 45px;
    font-size: 14px;
    width: calc(100% - 50px);
    @include media(991px); }
  &__btn {
    @extend %base-color;
    @extend %text--white;
    width: 50px;
    @include border-radius(0 5px 5px 0);
    font-size: rem(18px); } }


// .category-menu
//   @extend %d-flex
//   @extend %transition
//   &.active
//     left: 0
//   li
//     padding: rem(3px) rem(10px)
//     +media(767px)
//       padding: rem(10px) 0
//       border-bottom: 1px solid rgba(#fff, 0.15)
//     a
//       color: #fff
//       font-size: rem(14px)
//       &:hover
//         @extend %text--base
//   +media(767px)
//     position: fixed
//     z-index: 9999999
//     top: 0
//     left: -360px
//     padding: rem(20px)
//     min-width: 350px
//     background-color: $base-color-two
//     display: block
//     min-height: 100vh
//   +media(450px)
//     min-width: auto
//     width: 100%
//     left: -105%
// .header-cat-menu-open
//   background-color: transparent
//   font-size: rem(32px)
//   @extend %text--white
//   line-height: 1
//   display: none
//   +media(767px)
//     display: inline-block
// .header-cat-menu-close
//   @extend %position-absolute
//   top: 0
//   right: 0
//   background-color: $danger
//   font-size: rem(24px)
//   padding: rem(4px)
//   @extend %text--white
//   line-height: 1
//   display: none
//   +media(767px)
//     display: inline-block

// .header-search-from
//   @extend %d-flex
//   .form--control
//     background-color: transparent
//     border: 1px solid rgba(#fff, 0.15)
//     color: #fff
//     width: calc(100% - #{rem(35px)})
//     +placeholder(rgba(#fff, 0.5))
//     height: rem(35px)
//     +border-radius(5px 0 0 5px)
//     border-right: none
//     font-size: rem(14px)
//   &__btn
//     width: rem(35px)
//     @extend %base-color
//     color: $base-color-two
//     +border-radius(0 5px 5px 0)

.navbar-collapse {
  .menu-sidebar-close {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    font-size: rem(24px);
    line-height: 1;
    @extend %text--white;
    padding: rem(5px);
    background-color: $danger;
    @include media(991px) {
      display: inline-block; } }
  &.show {
    @include media(991px) {
      left: 0; } }
  @include media(991px) {
    display: block !important;
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: -360px;
    background-color: $base-color-two;
    margin-top: 0 !important;
    padding: rem(20px);
    min-width: rem(350px);
    max-width: 100%;
    min-height: 100vh;
    @include transition(all 0.3s); }
  @include media(767px) {
    max-height: rem(320px);
    overflow: auto; }
  @include media(450px) {
    width: 100%;
    min-width: auto;
    left: -105%; } }
.navbar-toggler {
  padding: 0;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none; } }

.menu-toggle {
  width: 35px;
  height: 24px;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  @extend %position-relative;
  &::before,
  &::after {
    @extend %position-absolute;
    content: '';
    top: 50%;
    left: 0;
    @extend %w-100;
    height: 2px;
    @extend %bg--white;
    margin-top: -1px;
    @extend %transition; } }
.navbar-toggler[aria-expanded="true"] {
  .menu-toggle {
    border-color: transparent;
    &::before {
      @include transform(rotate(45deg)); }
    &::after {
      @include transform(rotate(-45deg)); } } }
/* header css end */
