/* button css start */
button {
  &:focus {
    outline: none; } }

// BS defualt btn style
[class*="btn--"]:not(.btn--link, .btn--light) {
  color: #fff; }
.btn {
  padding: rem(14px) rem(30px);
  font-weight: 600;
  &:focus {
    box-shadow: none; } }

.btn--base {
  @extend %base-color;
  @extend %text--white;
  &:hover {
    background-color: darken($base-color, 5%);
    @extend %text--white; } }

.btn--dark {
  @extend %base-color-two;
  @extend %text--white;
  &:hover {
    background-color: darken($base-color-two , 5%);
    @extend %text--white; } }

// outline btn
.btn-outline--base {
  @extend %text--base;
  border: 1px solid $base-color;
  &:hover {
    @extend %base-color;
    @extend %text--white; } }

.btn-outline--dark {
  color: $heading-color;
  border: 1px solid $heading-color;
  &:hover {
    background-color: $heading-color;
    @extend %text--white; } }

.btn--capsule {
  @include border-radius(999px); }

.icon-btn {
  width: rem(28px);
  height: rem(28px);
  background-color: $base-color;
  color: #fff;
  @include border-radius(3px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #fff; }
  i[class*="la"] {
    font-size: rem(18px); } }
.btn--group {
  @include d-flex;
  align-items: center;
  margin-left: rem(-10px);
  margin-right: rem(-10px);
  *[class*="btn"] {
    margin: rem(5px) rem(10px);
    align-items: center;
    &.d-flex {
      padding: rem(8px) rem(35px); } }
  &.style--two {
    margin-left: rem(-5px);
    margin-right: rem(-5px);
    *[class*="btn"] {
      margin: rem(3px) rem(5px); } } }
[class*="btn"] {
  &.btn-md {
    padding: rem(10px) rem(20px);
    font-size: rem(16px); }
  &.btn-sm {
    padding: rem(6px) rem(10px);
    font-size: rem(14px); } }
/* button css end */
